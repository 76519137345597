import axios from 'axios';

const state = {
  studentProfile: null,
  isProfileLoading: false,
  status: null,
  candidateEmpFilters: {
    zipCode: null,
    zipCodeRange: 30,

    certifications: [],
    records: [],
    piis: [],
    availability: [],
    facilityType: null,
    workDay: [],
    workTime: [],
    langPreferences: [],

    experience: null,
    gender: null,
  },
};

const getters = {
  getStudentProfile: state => state.studentProfile,
  getCandidateEmpFilters: state => state.candidateEmpFilters,

  getIsProfLoad: state => state.isProfileLoading,
  getStatus: state => state.status,
};

const mutations = {
  updateMyStudentProfile: (state, updatedProfile) => {
    state.studentProfile = updatedProfile;
  },
  updateCandidateEmpFilters: (state, updatedFilters) => {
    state.candidateEmpFilters = { ...state.candidateEmpFilters, ...updatedFilters };
  },

  updateIsProfLoad: (state, status) => {
    state.isProfileLoading = status;
  },
  updateStatus: (state, status) => {
    state.status = status;
  },
};

const actions = {
  async getAllStudents(_obj, params) {
    const res = await axios.get(`api/students/`, { params });
    return res.data;
  },

  async getStudent(_obj, id) {
    const res = await axios.get(`api/students/${id}/`);
    return res.data;
  },

  async createStudent(_obj, data) {
    return axios.post(`api/students/`, data);
  },
  async createStudentUniversityConnection(_obj, data) {
    return axios.post(`api/student_contact_msgs/uni-contact/contact-me/`, data);
  },

  async getStudentUniConnectionStatus(_obj, params) {
    const res = await axios.get(`api/student_contact_msgs/uni-contact/is-contacted/`,  { params });
    return res.data;
  },

  async getStudentsConnectionRequests() {
    const res = (await axios.get(`api/student_contact_msgs/uni-contact/`)).data.data;
    return res;
  },

  async updateStudent(_obj, { id, data }) {
    return axios.patch(`api/students/${id}/`, data);
  },

  async deleteStudent(_obj, id) {
    return axios.delete(`api/students/${id}/`);
  },

  async getStudentUploadPresignedUrl(_obj, data) {
    return (await axios.post(`api/students/upload-url/`, data)).data.data;
  },

  // Student's own profile APIs.
  async getMyStudentProfile({ commit, state }, fetchNew = false) {
    if (state.studentProfile && !fetchNew) {
      // dispatch('getPiiStatus', { student_id: state.studentProfile.id });
      return state.studentProfile;
    } else {
      const profile = (await axios.get(`api/students/my-profile/`)).data.data;

      commit('updateMyStudentProfile', profile);
      // dispatch('getPiiStatus', { student_id: profile.id });
      return profile;
    }
  },

  async updateMyStudentProfile({ commit, dispatch }, { data }) {
    const profile = (await axios.patch(`api/students/update-profile/`, data)).data.data;
    dispatch('getPiiStatus');
    commit('updateMyStudentProfile', profile);
    commit('auth/updateMyProfile', profile.user, { root: true });

    return profile;
  },

  async updateMyPIIDocs({ dispatch }, { data }) {
    const resp = (await axios.patch(`api/students/student-update-piis/`, data)).data;
    dispatch('getPiiStatus');
    return resp;
  },

  async getMyResumeProfile() {
    const profile = (await axios.get(`api/students/my-resume-profile/`)).data.data;
    return profile;
  },

  async getResumePdfUrl() {
    const profile = (await axios.get(`api/students/resume-pdf/`)).data.data;
    return profile;
  },

  async updateMyResumeProfile(_obj, { data }) {
    const profile = (await axios.patch(`api/students/update-resume-profile/`, data)).data.data;
    return profile;
  },

  async updateCandidateEmpFilters({ commit }, { filters }) {
    commit('updateCandidateEmpFilters', filters);
  },

  async updateStudentCareerPreference(_obj, data) {
    return axios.post(`api/students/career-preference/`, data);
  },

  async contactStudent(_obj, data) {
    return axios.post(`api/students/contact/`, data);
  },
  async getStudentCareerPreference(_obj, params) {
    const res = axios.get(`api/students/get-career-preferences/`, { params });
    return (await res).data.data;
  },
  async getProgramSkills(_obj, params) {
    const res = await axios.get(`api/students/get-my-prg-skills/`, { params });
    return res.data.data.enrolled_program;
  },
  async getBlurredStudents(_obj, params) {
    const res = await axios.get(`api/students/blurred-students-list/`, { params });
    return res.data.data;
  },
  async getToBeHiredStudents(_obj, params) {
    const res = await axios.get(`api/students/to-be-hired-candidates/`, { params });
    return res.data.data;
  },
  async getStudentLastContacted(_obj, params) {
    const res = await axios.get(`api/student_contact_msgs/emp-latest-contact-date/`, { params });
    return res.data;
  },

  async getStudentUnlockedStatus(_obj, params) {
    const res = await axios.get(`api/students/unlocked-status/`, { params });
    return res.data;
  },

  async unlockEmpCandidate(_obj, { data }) {
    const resp = await axios.patch(`api/students/unlock-student/`, data);
    return resp.data;
  },
  async getEmpUnlockStudents(_obj, params) {
    const resp = await axios.get(`api/students/unlocked-student-school/`, { params });
    return resp.data;
  },
  async getStudentReqDocs(_obj, params) {
    const res = await axios.get(`api/students/student-required-docs/`, { params });
    return res.data;
  },
  async getStudentsUnlockedStatuses(_obj, data) {
    const res = await axios.post(`api/students/get-unlocked-statuses/`, data);
    return res.data;
  },
  async getOrgStudentsUnlockedStatuses(_obj, data) {
    const res = await axios.post(`api/students/get-org-unlocked-statuses/`, data);
    return res.data;
  },
  async getUpdatedPiis({ rootGetters }, data) {
    const token = rootGetters['auth/getPiiVerificationToken'];
    const res = await axios.post(`api/students/updated-piis-students/`, data, {
      headers: { 'X-Validated-Token': token },
    });
    return res.data;
  },
  async getStudentDocUrl({ rootGetters }, params) {
    const token = rootGetters['auth/getPiiVerificationToken'];
    const res = await axios.get(`api/students/student-docs-url/`, { params, headers: { 'X-Validated-Token': token } });
    return res.data;
  },
  async getPiiStatus({ commit }, params) {
    commit('updateIsProfLoad', true);
    const res = await axios.get(`api/students/pii/piis_status/`, { params });
    commit('updateStatus', res.data.data.pii_status);
    commit('updateIsProfLoad', false);
    return res.data;
  },
  async getGeneratedReport({ rootGetters }, data) {
    const token = rootGetters['auth/getPiiVerificationToken'];
    const resp = await axios.post(
      `api/students/students-piis-report/`,

      data,
      { headers: { 'X-Validated-Token': token } },
    );
    return resp.data;
  },

  async getStudentsPIIs(_obj, data) {
    const res = await axios.post(`api/students/piis-of-students/`, data);
    return res.data;
  },
  async updateIntroVideoUrl(_obj, params) {
    return axios.get(`api/students/intro-video-url-remove/`, params);
  },
  async postIntroVideoUrl(_obj, data) {
    return axios.post(`api/students/intro-success-video-url/`, data);
  },
  async getVideoUrl(_obj, params) {
    const res = await axios.get(`api/students/intro-video-ask-url/`, { params });
    return res.data;
  },

  async getUnlockedStudents(_obj, params) {
    const res = await axios.get(`api/students/unlocked-students/`, { params });
    return res.data;
  },
  async getStudentDashboardStatus(_obj, params) {
    const res = await axios.get(`api/students/student-dashboard/`, { params });

    return res.data;
  },
  async addStudentPii(_obj, data) {
    await axios.post(`api/students/pii/school-upload-student-piis/`, data);
  },

  async getOrgUnlockedStudents(_obj, params) {
    const res = await axios.get(`api/students/org-unlocked-students/`, { params });
    return res.data;
  },

  async getAssociatedStudents(_obj, params) {
    const res = await axios.get(`api/students/dashboard-associated-students/`, { params });
    return res.data;
  },

  async getUnlockStudentsCount(_obj, data) {
    const res = await axios.patch(`api/students/org-unlocked-students-count/`, data);
    return res.data;
  },

  async updateStudentProfile(_obj, { id, data }) {
    return axios.patch(`api/students/${id}/update-account/`, data);
  },

  async updateStudentHideProfile(_obj, { id, data }) {
    return axios.patch(`api/students/${id}/hide-account/`, data);
  },

  async updateStudentProgram(_obj, { id, data }) {
    return axios.patch(`api/students/${id}/school-update-programs/`, data);
  },

  async getStudentStatus(_obj, params) {
    const res = await axios.get(`api/students/current-status/`, { params });
    return res.data;
  },
  async exportPiiTable(_obj, data) {
    const res = await axios.post(`api/students/export-piis-table/`, data);
    return res.data;
  },
  async unlockOrgCandidate(_obj, { data }) {
    const resp = await axios.patch(`api/students/org-unlock-student/`, data);
    return resp.data;
  },
  async updateStudentTag(_obj, { id, data }) {
    return axios.patch(`api/students/${id}/school-update-tag/`, data);
  },

  async getAllCerts(_obj, params) {
    const res = await axios.get(`api/students/certs/`, { params });
    return res.data;
  },

  async getCert(_obj, id) {
    const res = await axios.get(`api/students/certs/${id}/`);
    return res.data;
  },

  async createCert(_obj, data) {
    return axios.post(`api/students/certs/`, data);
  },

  async updateCert(_obj, { id, data }) {
    return axios.patch(`api/students/certs/${id}/`, data);
  },

  async getAllOffers(_obj, params) {
    const res = await axios.get(`api/students/offers/`, { params });
    return res.data;
  },
  async getOffer(_obj, id) {
    const res = await axios.get(`api/students/offers/${id}/`);
    return res.data;
  },

  async createOffer(_obj, data) {
    return axios.post(`api/students/offers/`, data);
  },

  async updateOffer(_obj, { id, data }) {
    return axios.patch(`api/students/offers/${id}/`, data);
  },
  async getAllCourses(_obj, params) {
    const res = await axios.get(`api/students/courses/`, { params });
    return res.data;
  },
  async getCourse(_obj, id) {
    const res = await axios.get(`api/students/courses/${id}/`);
    return res.data;
  },

  async createCourse(_obj, data) {
    return axios.post(`api/students/courses/`, data);
  },

  async updateCourse(_obj, { id, data }) {
    return axios.patch(`api/students/courses/${id}/`, data);
  },
  async getDashboardScore(_obj, params) {
    const res = await axios.get(`api/students/dashboard-score/`, { params });
    return res.data;
  },
};

export const student = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
